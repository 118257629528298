import { CardCvcElement, CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import React from 'react'
import { useState } from 'react';
import { PaymentElement } from '@stripe/react-stripe-js';
import { usePaymentContext } from '../context/payments.context';

export const PaymentsForm = () => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("")
    const stripe = useStripe();
    const elements = useElements();
    const { intent } = usePaymentContext();

  
    const handleSubmit = async (event) => {
      // We don't want to let default form submission happen here,
      // which would refresh the page.
      event.preventDefault();
  
      if (!stripe || !elements) {
        // Stripe.js hasn't yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return;
      }
  
      const result = await stripe.confirmPayment({
        //`Elements` instance that was used to create the Payment Element
        elements,
        //redirect: 'if_required',
        confirmParams: {
          return_url: "https://www.diane.es/auth/payment/confirm",
          //return_url: "http://localhost:3000/auth/payment/confirm",
        }
      });
  
  
      if (result.error) {
        // Show error to your customer (for example, payment details incomplete)
        console.log(result.error.message);
      } else {
        console.log(result)
        
        
        // Your customer will be redirected to your `return_url`. For some payment
        // methods like iDEAL, your customer will be redirected to an intermediate
        // site first to authorize the payment, then redirected to the `return_url`.
      }
    };
   

    return (
        <div>
            <form onSubmit={handleSubmit}>
                <PaymentElement />
                <button className="payment_button" disabled={!stripe}>Submit</button>
            </form>
        </div>

    )
}
