import React, { useState } from 'react'
import background from '../../assets/video/background.mp4';
import { useForm } from '../../hooks/useForm'
import { Global } from '../../helpers/Global';
import useAuth from '../../hooks/useAuth'
import { Link } from 'react-router-dom';
import { rememberService } from '../../services/public.service';
import { useDispatch } from 'react-redux';
import { login } from '../../redux/states/auth';
import Popup from 'reactjs-popup';
import { Dialog } from '../layout/utils/Dialog';
import { useNavigate } from "react-router-dom";
import useFetch from '../../hooks/useFetch';
import { ProgressSpinner } from 'primereact/progressspinner';

export const ForgetPassword = () => {

    const { form, changed } = useForm({});
    const [open, setOpen] = useState(false);
    const [headerResponse, setHeaderResponse] = useState(false);
    const [descriptionResponse, setDescriptionResponse] = useState(false);
    const navigate = useNavigate();
    const { loading, callEndpoint } = useFetch();

    const closeModal = () => {
        navigate("/login");
        setOpen(false);
    }

    const rememberPass = async (e) => {
        e.preventDefault();
        setHeaderResponse(null);
        setDescriptionResponse(null);

        try {
            let remember = form;
            const request = await callEndpoint(rememberService(remember));

            if (request.data.status === "success") {
                setOpen(true);
            } else {
                setError();
            }
        } catch (error) {
            setError();
        }

    }

    const setError = () => {
        setHeaderResponse("Error en la autenticación");
        setDescriptionResponse("Hubo un error con tu email");
        setOpen(o => !o);
    }
    return (
        <div className='login-page card-container'>
            <Popup open={open} closeOnDocumentClick onClose={closeModal}>
                <Dialog header={headerResponse ? headerResponse : "En breves recibiras un correo"} description={descriptionResponse ? descriptionResponse : "Ahora puedes iniciar sesión"} Accept={closeModal} acceptText="Aceptar" />
            </Popup>
            <video className='videoTag' autoPlay loop muted>
                <source src={background} type='video/mp4' />
            </video>
            <div class="circle1"></div>
            <div class="circle2"></div>
            <div class="container">
                <div class="log-card">
                    <p class="heading">No recuerdas tu contraseña?</p>
                    <p>Escribe tu correo asociado a tu cuenta.</p>
                    <form className='form-login' onSubmit={rememberPass}>
                        <div class="input-group">
                            <label htmlFor='email' className="text">Email</label>
                            <input className="input" type='email' name='email' onChange={changed} />
                        </div>
                        {loading && <ProgressSpinner style={{width: '50px', height: '50px', left: '40%'}} strokeWidth="8"  />
}
                        {!loading &&<input type='submit' className="btn" value="Enviar" />}
                    </form>
                </div>
            </div>
        </div>

    )
}
