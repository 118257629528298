import React, {useState} from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { Nav } from './Nav'
import { useSelector } from 'react-redux';

export const PublicLayout = () => {
    const userState = useSelector((store) => store.auth);

    return (
        <>
            <Nav />
            <section className="layout__content">
                { !userState.user ?
                    <Outlet></Outlet>
                    :
                    <Navigate to="auth" />
                }
            </section>
        </>
    )
}
