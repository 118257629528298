import React from 'react'
import ReactCompareImage from 'react-compare-image';
import result_down from '../../assets/img/result_down.jpg';
import result_up from '../../assets/img/result_up.jpg';
import { useNavigate } from "react-router-dom";

export const ComparerImages = ({ button = true, image1 = null, image2 = null }) => {
  const navigate = useNavigate();

  const tryDiane = () => {
    navigate("/login");
  }

  return (
    <div className='comparer'>
      {button &&
        <>

          <button className='try-tool' onClick={tryDiane}>Prueba DIANE<span className='logo-button'><img alt="DIANE - mejora la calidad de tus imágenes" src='/logo.png' /></span></button>
          <h2 className='tool-subtitle'>
            Aumenta la resolución y calidad. Dale vida a cada detalle
            <p className='tool-subtitle2'>
              No dejes que la baja resolución arruine los momentos capturados. Con DIANE, puedes mejorar la calidad de tus imágenes, resaltando cada detalle con precisión. Transforma esas fotos borrosas en imágenes nítidas y llenas de vida, listas para impresionar.
            </p>
          </h2>
        </>
      }


      <ReactCompareImage aspectRatio="wider" leftImageAlt="IA Herramienta Fotógrafos" rightImageAlt='IA Herramienta Fotografía' leftImage={image1 ? image1 : result_down} rightImage={image2 ? image2 : result_up} />

    </div>
  )
}
