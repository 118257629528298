import logo from './logo.svg';
import './App.css';
import { RouterPrincipal } from './routers/RouterPrincipal';
import CookieConsent from "react-cookie-consent";
import { PrimeReactProvider } from 'primereact/api'

function App() {
  return (
    <>
      <PrimeReactProvider>
        <RouterPrincipal />
      </PrimeReactProvider>
    </>

  );
}

export default App;
