import React from 'react'
import { Routes, Route, NavLink, BrowserRouter, Navigate } from 'react-router-dom';
export const Nav = () => {
    return (
        <>
            <div className='header'>
                <div className='logo'>
                    <img alt="Mejora la calidad de tus imágenes" src='/logo.png' />
                </div>
                <nav>
                    <ul>
                        <li>
                            <NavLink to='/home' className={({ isActive }) => isActive ? "activado" : ""}> Inicio </NavLink>

                        </li>
                        <li>
                            <NavLink to='/register' className={({ isActive }) => isActive ? "activado" : ""}> Registro </NavLink>

                        </li>
                        <li>
                            <NavLink to='/login' className={({ isActive }) => isActive ? "activado" : ""}> Login </NavLink>

                        </li>
                    </ul>
                </nav>
                </div>
            </>
            )
}
