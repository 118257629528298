import React from 'react'

export const Card = ({ price = null, title, subtitle, description, clickEvent }) => {


    return (
        <div class="plan">
            <div class="inner">
                <span class="pricing">
                    {price && <span>
                        {price}€
                    </span>}
                </span>
                <p class="title">{title}</p>
                <p class="info">{subtitle}</p>
                <ul class="features">
                    <li>
                        <span class="icon">
                            <svg height="24" width="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0 0h24v24H0z" fill="none"></path>
                                <path fill="currentColor" d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"></path>
                            </svg>
                        </span>
                        <span>{description}</span>
                    </li>
                </ul>
                {clickEvent &&
                    <div class="action" onClick={clickEvent}>
                        <a class="button" href="#">
                            Choose plan
                        </a>
                    </div>
                }
            </div>
        </div>
    )
}
