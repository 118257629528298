import React, { useState } from 'react'
import { useForm } from '../../../hooks/useForm'
import { Global } from '../../../helpers/Global';
import Popup from 'reactjs-popup';
import { Dialog } from '../utils/Dialog';

export const Contact = () => {
    const { form, changed } = useForm({});
    const [saved, setSaved] = useState("not_sended");
    const [open, setOpen] = useState(false);
    const [headerResponse, setHeaderResponse] = useState(false);
    const [descriptionResponse, setDescriptionResponse] = useState(false);

    const closeModal = () => {
        setOpen(false);
    }

    const contact = async (e) => {
        setDescriptionResponse(null);
        setHeaderResponse(null);
        e.preventDefault();
        let body = form;
        const request = await fetch(Global.url + "user/send-email", {
            method: "POST",
            body: JSON.stringify(body),
            headers: {
                "Content-Type": "application/json"
            }
        });

        let data = await request.json();
        if (data.status === "success") {
            setSaved("saved");
            setOpen(o => !o);
        } else {
            setSaved("error");
            setHeaderResponse("Error en el envio");
            setDescriptionResponse(data.message);
            setOpen(o => !o);

        }
    }

    return (
        <div className='contactus'>
            <Popup open={open} closeOnDocumentClick onClose={closeModal}>
                <Dialog  header={headerResponse ? headerResponse: "El mensaje se ha enviado!"} description={descriptionResponse ? descriptionResponse: "Nuestro equipo se pondra en contacto contigo!"} Accept={closeModal} acceptText="Aceptar" />
            </Popup>
            <div className='titles'>
                <h2 className='tool-subtitle3'>
                Contáctanos
                </h2>

                <p className='tool-subtitle2'>
                Nuestro equipo está listo para ayudarte.
                </p>
            </div>

            <form class="form" onSubmit={contact}>
                <div class="title">Contactanos</div>
                <input type="email" name="email" placeholder="Email" class="input" onChange={changed}/>
                <textarea placeholder="Que necesitas?" name="description" onChange={changed}></textarea>

                <input type='submit' className="btn" value="Enviar" />
            </form>
        </div>
    )
}
