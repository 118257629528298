export const Global = {
    url: "https://dianeapi-production-88d5.up.railway.app/api/",
    url_flask: "https://flaskdianeapi-production-8494.up.railway.app/",
    url_local: "http://127.0.0.1:4000/api/"
};
export const Products = {
    standard : {
        price:"9,99",
        title:"Standard",
        subtitle:"Diane IA",
        description:"20 creditos",
    },
    profesional: {
        price:"19,99",
        title:"Profesional",
        subtitle:"Diane IA",
        description:"50 creditos",
    }
}