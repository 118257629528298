export const createUserAdapter = (user) => ({ 
    id: user.user.id, 
    name: user.user.name,
    surname: user.user.surname,
    credits: user.user.credits,
    role: user.user.role,
    token: user.token
});

export const profileAdapter = (user) => ({ 
    id: user.user._id, 
    name: user.user.name,
    email: user.user.email,
    surname: user.user.surname,
    credits: user.user.credits,
    role: user.user.role
});
