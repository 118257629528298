import React, { useEffect } from 'react'
import { Header } from '../layout/public/Header';
import { Footer } from '../layout/public/Footer';
import { ComparerImages } from '../dianeAI/ComparerImages';
import { Contact } from '../layout/public/Contact';
import colors1 from '../../assets/img/colors1.jpg';
import colors2 from '../../assets/img/colors2.jpg';
import background1 from '../../assets/img/background1.jpg';
import background2 from '../../assets/img/background2.jpg';
import { Card } from '../layout/utils/Card';
import { useNavigate } from "react-router-dom";
import { Products } from '../../helpers/Global';
import { useSelector } from 'react-redux';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const variants = {
  hidden: { opacity: 0, x: -100 },
  visible: { opacity: 1, x: 0 }
};


const AnimatedSection = ({ children }) => {
  const { ref, inView } = useInView({
    once: true,
    threshold: 0.50
  });
  const mainControls = useAnimation();
  useEffect(() => {
    if (inView) {
      mainControls.start("visible")
    }

  }, [inView])

  return (
    <motion.div
      ref={ref}
      initial="hidden"
      animate={mainControls}
      transition={{ duration: 0.5, delay: 0.25 }}
      variants={variants}
    >
      {children}
    </motion.div>
  );
};
export const Home = () => {
  const navigate = useNavigate();
  const userState = useSelector((store) => store.auth);

  return (
    <div className="App">
      <section className='section-comparer'>
        <AnimatedSection>
          <h1 className='tool-subtitle'>
            Transforma tus fotografías en obras de arte con DIANE
            <p className='tool-subtitle2'>
              Potencia cada imagen con la magia de la edición profesional. DIANE es la herramienta perfecta para fotógrafos que desean elevar la calidad, limpieza y color de sus fotos sin complicaciones. ¡Con solo un clic, lleva tus fotos al siguiente nivel! </p>
          </h1>
        </AnimatedSection>
        <div className='comparer_section'>
          <AnimatedSection>
            <ComparerImages />
          </AnimatedSection>
        </div>


      </section>
      <br />

      <section className='section-enchancer'>
        <div className='content-enchancer'>
        <AnimatedSection>

            <h2 className='tool-subtitle3'>
              Colores que cuentan historias
            </h2>
          </AnimatedSection>
          <AnimatedSection>

            <p className='tool-subtitle2'>
              Haz que tus imágenes hablen por sí solas con colores vibrantes y equilibrados. DIANE te permite ajustar y mejorar los tonos de tus fotografías para que cada foto refleje la emoción y atmósfera exacta que deseas transmitir. Dale a tus fotos el color que merecen.</p>
          </AnimatedSection>

        </div>
        <div className='enchancer'>
          <AnimatedSection>

            <ComparerImages image1={colors1} image2={colors2} button={false} />
          </AnimatedSection>

        </div>
      </section>

      <section className='section-background'>


        <div className='enchancer'>
          <AnimatedSection>

            <ComparerImages image1={background1} image2={background2} button={false} />
          </AnimatedSection>

        </div>


        <div className='content-enchancer'>

          <AnimatedSection>

            <h2 className='tool-subtitle'>
              Elimina el desorden. Enfócate en lo importante
            </h2>
          </AnimatedSection>
          <AnimatedSection>

            <p className='tool-subtitle2'>
              Los fondos no deseados ya no serán un problema. Con DIANE, puedes limpiar y simplificar tus fotos eliminando cualquier elemento de fondo que reste protagonismo al elemento principal de tu instantánea. Deja que lo esencial brille sin distracciones.
            </p>
          </AnimatedSection>
        </div>

      </section>
      {console.log("USER", userState.user)}
      {
        userState.user &&

        <section className='section-enchancer' id='payment'>

          <Card price={Products.profesional.price} title={Products.profesional.title} subtitle={Products.profesional.subtitle} description={Products.profesional.description} clickEvent={(e) => {
            e.preventDefault()
            navigate("/auth/payment/profesional")
          }} />

          <Card price={Products.standard.price} title={Products.standard.title} subtitle={Products.standard.subtitle} description={Products.standard.description} clickEvent={(e) => {
            e.preventDefault()
            navigate("/auth/payment/standard")
          }} />

        </section>

      }
      <section>
        <AnimatedSection>
          <Contact />
        </AnimatedSection>



      </section>

      <Footer />
    </div >
  )
}
