import axios from 'axios';
import { Global } from '../helpers/Global';
import { loadAbort } from '../helpers/load-abort-axios.utility';


export const loginService = (user) => {
    return {
        call: axios.post(Global.url + "user/login", user)
    }
}

export const registerService = (user) => {
    return {
        call: axios.post(Global.url + "user/register", user)
    }
}


export const rememberService = (email) => {
    return {
        call: axios.post(Global.url + "user/remember_user", email)
    }
}

export const changePassword = (newPass, oldPass) => {
    return {
        call: axios.post(Global.url + "user/change_password", {newPass}, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": localStorage.getItem("token")
            }
        })
    }
}


export const profile = (userId) => {
    const controller =  loadAbort();
    return {
        call: axios.get(Global.url + "user/profile/" + userId, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": localStorage.getItem("token")
            }
        }),
        controller
    }
}


export const activateUserService = (tk) => {
    return {
        call: axios.get(Global.url + "user/verify-user/" + tk, {
            headers: {
                "Content-Type": "application/json",
            }
        })
    }
}

export const paymentSecret = (amount) => {
    return {
        call: axios.post(Global.url + "user/create-payment-intent", { type : amount }, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": localStorage.getItem("token")
            }
        })
    }
}

export const confirmPayment = (secret) => {
    return {
        call: axios.post(Global.url + "payment/confirm-payment", secret, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": localStorage.getItem("token")
            }
        })
    }
}

export const uploadImage = (formData) => {
    return {
        call: axios.post(Global.url + "publication/upload", formData, {
            headers: {
                "Authorization": localStorage.getItem("token")
            }
        })
    }
}