import React from 'react'
import { PaymentProvider } from './context/payments.context'
import { PaymentsLayout } from './components/PaymentsLayout'

export const PaymentsPage = () => {
    return (
        <>
            <PaymentProvider>
                <PaymentsLayout />
            </PaymentProvider>
        </>
    )
}
