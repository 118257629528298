import { AxiosResponse } from 'axios'
import { useEffect, useState } from 'react'

const useFetch = () => {
    const [loading, setLoading] = useState(false);
    let controller;

    const callEndpoint = async(axiosCall) => {
        console.log(axiosCall);
        if (axiosCall.controller) controller = axiosCall.controller;
        setLoading(true);
        let result = {};
        try {
            result = await axiosCall.call
            console.log(result);

        } catch(err) {
            setLoading(false)
            throw err;
        }
        setLoading(false);
        return result;

    };

    const cancelEndpoint = () => {
        setLoading(false);
        controller && controller.abort();
    }

    useEffect(() => {
        return () =>{
            cancelEndpoint();
        }
    }, [])
 return {loading, callEndpoint};
}

export default useFetch;