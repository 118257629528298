import { createSlice } from "@reduxjs/toolkit";
import { profile } from "../../services/public.service";
import { profileAdapter } from "../../adapters/user.adapter";
import { Logout } from "../../components/user/Logout";

const initialState = {
    token: localStorage.getItem("token"),
    user: JSON.parse(localStorage.getItem("user")),
  };

export const authSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {
        login: (state, action) => {
            localStorage.setItem("token", action.payload.token);
            localStorage.setItem("user", JSON.stringify(action.payload));
            state.token = action.payload.token;
            state.user = action.payload;
        },
        logout: (state, action) => {
            localStorage.clear();
            state.token = null;
            state.user = null;
        },
        updateUser: (state, action) => {
            state.user = {...action.payload};
        }
    }
})

export const { login, logout, updateUser } = authSlice.actions;