import React from 'react'

export const Dialog = ({header, description, Accept= null, acceptText, declineText, Decline = null}) => {
    return (
        <div className='dialog'>
            <div class="card">
                <p class="cookieHeading">{header}</p>
                <p class="cookieDescription">{description}</p>

                <div class="buttonContainer">
                    {Accept &&
                        <button onClick={Accept} class="acceptButton">{acceptText}</button>

                    }   
                    {Decline &&
                        <button onClick={Decline} class="declineButton">{declineText}</button>

                    }
                </div>
            </div>


        </div>
    )
}
