import React from 'react'
import { Routes, Route, NavLink, BrowserRouter, Navigate, Link } from 'react-router-dom';
import { Home } from '../components/home/Home';
import { DianeAI } from '../components/dianeAI/DianeAI';
import { Login } from '../components/user/Login';
import { PublicLayout } from '../components/layout/public/PublicLayout';
import { PrivateLayout } from '../components/layout/private/PrivateLayout';
import { Register } from '../components/user/Register';
import { AuthProvider } from '../context/AuthProvider';
import { Logout } from '../components/user/Logout';
import { Contact } from '../components/layout/public/Contact';
import { PaymentsConfirmPage } from '../components/payments/PaymentsConfirmPage';
import { Provider } from 'react-redux';
import store from '../redux/store';
import { PaymentsPage } from '../components/payments/PaymentsPage';
import { ConfirmPage } from '../components/user/ConfirmPage';
import { ForgetPassword } from '../components/user/ForgetPassword';
import { ChangePassword } from '../components/user/ChangePassword';
export const RouterPrincipal = () => {
    return (
        <BrowserRouter>
            <Provider store={store}>
                    <Routes>
                        <Route path='/' element={<PublicLayout />}>
                            <Route index element={<Home />}></Route>
                            <Route path='home' element={<Home />}></Route>
                            <Route path='login' element={<Login />}></Route>
                            <Route path='register' element={<Register />}></Route>
                            <Route path='contact' element={<Contact />}></Route>
                            <Route path='verify-email/:token' element={<ConfirmPage />}></Route>
                            <Route path='remember_password' element={<ForgetPassword />}></Route>
                        </Route>

                        <Route path='/auth' element={<PrivateLayout />}>
                            <Route index element={<DianeAI />}></Route>
                            <Route path='home' element={<Home />}></Route>
                            <Route path='diane-ai' element={<DianeAI />}></Route>
                            <Route path='logout' element={<Logout />}></Route>
                            <Route path='payment/:type' element={<PaymentsPage />}></Route>
                            <Route path='payment/confirm' element={<PaymentsConfirmPage />}></Route>
                            <Route path='contact' element={<Contact />}></Route>
                            <Route path='change_password' element={<ChangePassword />}></Route>
                        </Route>
                        <Route path='*' element={
                            <>
                                <p>
                                    <h2>Error 404</h2>
                                    <Link to="/">Volver al inicio</Link>
                                </p>
                            </>
                        }></Route>
                    </Routes>
            </Provider>
        </BrowserRouter>
    )
}
