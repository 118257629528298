import React, { createContext, useContext, useState} from 'react'

export const PaymentContext = createContext();

export const PaymentProvider = ({ children }) => {
    const [type, setType] = useState('')
    const [intent, setIntent] = useState({})

  return <PaymentContext.Provider value={{type, setType}}>{children}</PaymentContext.Provider>
}

export const usePaymentContext = () => {
    const context = useContext(PaymentContext);
    if (context === undefined) {
        throw new Error('Error inesperado');

    }
    return context;
}