import React, { useEffect, useRef, useState } from 'react'
import background from '../../assets/video/background_old.mp4';
import prevideo from '../../assets/img/pre-video.png';
import { useForm } from '../../hooks/useForm'
import { Global } from '../../helpers/Global';
import Popup from 'reactjs-popup';
import { Dialog } from '../layout/utils/Dialog';
import { useNavigate } from "react-router-dom";
import { registerService } from '../../services/public.service';
import useFetch from '../../hooks/useFetch';
import { ProgressSpinner } from 'primereact/progressspinner';
import { LazyLoadImage } from "react-lazy-load-image-component";

export const Register = () => {


    const { form, changed } = useForm({});
    const [saved, setSaved] = useState("not_sended");
    const [open, setOpen] = useState(false);
    const [headerResponse, setHeaderResponse] = useState(false);
    const [descriptionResponse, setDescriptionResponse] = useState(false);
    const [isVideoVisible, setIsVideoVisible] = useState(false);
    const { loading, callEndpoint } = useFetch();
    const navigate = useNavigate();
    const videoRef = useRef(null);

    useEffect(() => {
        const handlePlay = () => {
            playVideo();
        };

        const videoElement = videoRef.current;
        videoElement.addEventListener('play', handlePlay);

        return () => {
            videoElement.removeEventListener('play', handlePlay);
        };
    }, []);


    const playVideo = () => {
        setIsVideoVisible(true);
      };


    const closeModal = () => {
        if (saved !== "error") {
            navigate("/login");
        }
        setOpen(false);
    }

    const saveUser = async (e) => {
        setDescriptionResponse(null);
        setHeaderResponse(null);
        e.preventDefault();
        let newUser = form;
        form.email = form.email.toLowerCase();
        form.name = form.name.replace(/\s+/g, "");
        const request = await callEndpoint(registerService(newUser));
        if (request.data.status === "success") {
            setSaved("saved");
            setOpen(o => !o);
        } else {
            setSaved("error");
            setHeaderResponse("Error en el Registro");
            setDescriptionResponse(request.data.message);
            setOpen(o => !o);

        }
    }


    return (
        <div className='login-page card-container'>
            <Popup open={open} closeOnDocumentClick onClose={closeModal}>
                <Dialog header={headerResponse ? headerResponse : "El registro se ha completado!"} description={descriptionResponse ? descriptionResponse : "Ahora puedes iniciar sesión"} Accept={closeModal} acceptText="Aceptar" />
            </Popup>
            <div id="preVideo" className={`videoTag ${isVideoVisible ? 'fade-out' : 'fade-in'}`} >
                <LazyLoadImage src={prevideo}/>
            </div>
            <video className={`videoTag ${isVideoVisible ? 'fade-in' : 'fade-out'}`} ref={videoRef} autoPlay loop muted>
                <source src={background} type='video/mp4' />
            </video>
            <div className="circle1"></div>
            <div className="circle2"></div>
            <div className="container">
                <div className="log-card">
                    <p className="heading">Bienvenido</p>
                    <p>Regístrate y comienza a usar Diane hoy mismo.</p>

                    <div className="input-group">
                        <form classNameName='registerForm' onSubmit={saveUser}>

                            <label htmlFor='name' className="text">Nombre</label>
                            <input className="input" name="name" type="text" placeholder="For Ex: Jayakrishna007" onChange={changed} />
                            <label htmlFor='surname' className="text">Apellido</label>
                            <input className="input" type='text' name='surname' onChange={changed} />
                            <label htmlFor='email' className="text">Email</label>
                            <input className="input" type='email' name='email' onChange={changed} />
                            <label htmlFor='password' className="text">Contraseña</label>
                            <input className="input" type='password' name='password' onChange={changed} />
                            {loading && <ProgressSpinner style={{ width: '50px', height: '50px', left: '40%' }} strokeWidth="8" />
                            }
                            {!loading && <input type='submit' className="btn" value="Registro" />}

                        </form>
                    </div>
                </div>
            </div>
        </div>

    )
}
