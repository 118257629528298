import React from 'react'

export const Footer = () => {
  return (
    <div>
    <footer className='footer'>
        Created by Nestor Galvañ &copy; - 2023
    </footer>
    </div>
  )
}
