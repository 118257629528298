import React, { useState } from 'react'
import background from '../../assets/video/background.mp4';
import { useForm } from '../../hooks/useForm'
import { changePassword } from '../../services/public.service';
import Popup from 'reactjs-popup';
import { Dialog } from '../layout/utils/Dialog';
import useFetch from '../../hooks/useFetch';
import { ProgressSpinner } from 'primereact/progressspinner';

export const ChangePassword = () => {
    const { form, changed } = useForm({});
    const [open, setOpen] = useState(false);
    const [headerResponse, setHeaderResponse] = useState(false);
    const [descriptionResponse, setDescriptionResponse] = useState(false);
    const { loading, callEndpoint } = useFetch();

    const closeModal = () => {
        setOpen(false);
    }

    const changePass = async (e) => {
        e.preventDefault();
        try {
            setHeaderResponse(null);
            setDescriptionResponse(null);
            let { newPass } = form;
            const request = await callEndpoint(changePassword(newPass));
            console.log(request.data);
            if (request.data.status === "success") {
                setOpen(true);
            } else {
                setError();
            }
        } catch (error) {
            setError();
        }

    }

    const setError = () => {
        setHeaderResponse("Error");
        setDescriptionResponse("Hubo un error al cambiar tu contraseña");
        setOpen(o => !o);
    }
    return (
        <div className='login-page chpass-page card-container'>
            <Popup open={open} closeOnDocumentClick onClose={closeModal}>
                <Dialog header={headerResponse ? headerResponse : "Tu contraseña se cambio con exito"} description={descriptionResponse ? descriptionResponse : "Ya puedes usar tu nueva contraseña"} Accept={closeModal} acceptText="Aceptar" />
            </Popup>

            <div class="container">
                <div class="log-card chpass-card">
                    <p class="heading">Cambiar contraseña</p>
                    <p>Modifica tu contraseña y empieza a usarla.</p>

                    <form className='form-login' onSubmit={changePass}>
                        <div class="input-group">
                            <label htmlFor='newPass' className="text">Nueva Contraseña</label>
                            <input className="input" type='password' name='newPass' onChange={changed} />
                        </div>
                        {loading && <ProgressSpinner style={{ width: '50px', height: '50px', left: '40%' }} strokeWidth="8" />
                        }
                        {!loading && <input type='submit' className="btn" value="Enviar" />}
                    </form>
                </div>
            </div>
        </div>

    )
}
