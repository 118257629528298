import React from 'react'

export const Header = () => {
    return (
        <div className='header'>
            <div className='logo'>
                <img alt="Mejora la calidad de tus imágenes" src='/logo.png'/>
            </div>
            <nav>
                <ul>
                    <li>
                        Contacto
                    </li>
                    <li>
                        Serendipia
                    </li>
                    <li>
                        Upscale an Image
                    </li>
                </ul>
            </nav>
        </div>
    )
}
