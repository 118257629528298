import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux';
import { logout } from '../../redux/states/auth';

export const Logout = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(logout());

        navigate('/login');
    }, [])
    
  return (
    <h2>Cerrando sesión...</h2>
  )
}
