import React, { useRef } from 'react'
import { Routes, Route, NavLink, BrowserRouter, Navigate } from 'react-router-dom';
import { TieredMenu } from 'primereact/tieredmenu';
import { useNavigate } from "react-router-dom";

export const Nav = () => {
    const navigate = useNavigate();
    const settings = useRef(null);
    const menuModel = [{ label: "Cerrar Sesión", command:()=>{ navigate("logout"); }}, { label: "Cambiar Contraseña", command:()=>{ navigate("change_password"); }}];
    return (
        <>
            <div className='header'>
                <div className='logo'>
                    <img alt="Herramienta hecha para fofografos" src='/logo.png' />
                </div>
                <nav>
                    <ul>
                        <li>
                            <NavLink to='home' className={({ isActive }) => isActive ? "activado" : ""}> Inicio </NavLink>

                        </li>

                        <li>
                            <NavLink to='/auth' className={({ isActive }) => isActive ? "activado" : ""}> Diane </NavLink>

                        </li>

                        <li>
                            <div className='card flex justify-content-center'>
                                <TieredMenu model={menuModel} popup ref={settings} />
                                <NavLink onClick={(e) => settings.current.toggle(e)} className={({ isActive }) => isActive ? "activado" : ""}> Ajustes </NavLink>
                            </div>
                        </li>
                    </ul>
                </nav>
            </div>
        </>
    )
}
