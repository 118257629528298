import React, { useState } from 'react'
import { Dropzone, FileItem, FileMosaic } from "@dropzone-ui/react";
import { Global } from '../../helpers/Global';
import { Download } from './Download';
import useAuth from '../../hooks/useAuth';
import Popup from 'reactjs-popup';
import { Dialog } from '../layout/utils/Dialog';
import { HashLink as Link } from 'react-router-hash-link';
import store from '../../redux/store';
import { useSelector } from 'react-redux';
import useFetch from '../../hooks/useFetch';
import { uploadImage } from '../../services/public.service';

export const DropZone = ({ imageUpscaled, setImageUpscaled, getImage, setOriginalImage, setGetImageUpscaled, setLoading, option }) => {
    const [files, setFiles] = React.useState([]);
    const [credits, setCredits] = React.useState(null);
    const [open, setOpen] = useState(false);
    const [headerResponse, setHeaderResponse] = useState(false);
    const [descriptionResponse, setDescriptionResponse] = useState(false);
    const userState = useSelector((store) => store.auth);
    const { callEndpoint } = useFetch();

    const updateFiles = (incommingFiles) => {
        console.log(incommingFiles[0]);
        if (incommingFiles[0] && incommingFiles[0].valid) {
            setFiles(incommingFiles);
        } else {
            setFiles([]);
        }
    };
    const [download, setDownload] = React.useState(false)

    const closeModal = () => {
        setOpen(false);
    }
    const uploadFiles = async () => {
        setOriginalImage(null);
        setImageUpscaled(null);
        setGetImageUpscaled(null);
        setLoading(true);
        setDownload(false);
        const fileInput = files[0].file;
        const formData = new FormData();

        formData.append("file", fileInput);
        formData.append("option", option);
        const request = await callEndpoint(uploadImage(formData));
        console.log(request);
        if (request.status === 200) {
            var data = request.data;
            let userData = JSON.parse(localStorage.getItem("user"));
            userData.credits = data.credits;
            localStorage.setItem("user", JSON.stringify(userData));
            setImageUpscaled(data.responseData.data.output.tmp_url)
            setOriginalImage(URL.createObjectURL(fileInput));
            getImage(data.responseData.data.output.tmp_url);
            setGetImageUpscaled(data.responseData.data.output.tmp_url)
            setCredits(data.credits);
            setDownload(true);
            setLoading(false);
            setFiles([]);

        } else {
            if (data.message) {
                setHeaderResponse("Ha ocurrido un error");
                setDescriptionResponse(data.message);
            }
            setLoading(false)
            setOpen(o => !o);
        }
    };

    return (
        <div className='drop-zone'>
            <Popup open={open} closeOnDocumentClick onClose={closeModal}>
                <Dialog header="Parece que hubo un problema" description={descriptionResponse ? descriptionResponse : "Intentelo mas tarde"} Accept={closeModal} acceptText="Aceptar" />
            </Popup>
            <div className='drop-button-content'>
                {files.length > 0 && <button class="btn-diane" onClick={uploadFiles}>
                    <span class="btn-diane-content">Upload </span>
                </button>
                }
                {download &&
                    <Download url={imageUpscaled} filename={imageUpscaled.substring(imageUpscaled.lastIndexOf('/') + 1)} />
                }
            </div>
            <div className='credits-zone'>
                <Link to="/auth/home#payment">
                    <div className='credits'>{credits ? credits : userState.user.credits} créditos </div>
                </Link>
            </div>
            {userState.user.credits > 0 &&
                <Dropzone maxFiles={1}
                    accept=".jpg, .jpeg. .JPEG, .JPG"
                    maxFileSize={15000000}
                    onChange={updateFiles}
                    value={files}>
                    {files.map((file) => (
                        <FileMosaic {...file} preview />
                    ))}

                </Dropzone>
            }
        </div>
    )
}
