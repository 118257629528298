import React, { useState, useEffect } from 'react'
import { DropZone } from './DropZone'
import ReactCompareImage from 'react-compare-image';
import { Global } from '../../helpers/Global';
import { Loader } from '../layout/utils/Loader';
import { CircleSpinnerOverlay, FerrisWheelSpinner } from 'react-spinner-overlay'
import result_down from '../../assets/img/result_down.jpg';
import result_up from '../../assets/img/result_up.jpg';
import colors1 from '../../assets/img/colors1.jpg';
import colors2 from '../../assets/img/colors2.jpg';
import background1 from '../../assets/img/background1.jpg';
import background2 from '../../assets/img/background2.jpg';
import { profile } from '../../services/public.service';
import { useDispatch, useSelector } from 'react-redux';
import { profileAdapter } from '../../adapters/user.adapter';
import { updateUser } from '../../redux/states/auth';
import useFetch from '../../hooks/useFetch';

export const DianeAI = () => {
    const [imageUpscaled, setImageUpscaled] = useState();
    const [getImageUpscaled, setGetImageUpscaled] = useState(null);
    const [originalImage, setOriginalImage] = useState(null)
    const [loading, setLoading] = useState(false)
    const [image, setImage] = useState(null)
    const [imageOption1, setImageOption1] = useState(result_up);
    const [imageOption2, setImageOption2] = useState(result_down);
    const [option, setOption] = useState("1");
    const dispatch = useDispatch();
    const userState = useSelector((store) => store.auth);
    const { callEndpoint } = useFetch();

    const getImage = (img) => {
        setImage(img);
    }
    const onOptionChange = e => {
        console.log(e.target.value);
        if(e.target.value === "1") {
            setImageOption1(result_up);
            setImageOption2(result_down);
            setOption("1");
        } else if(e.target.value === "2") {
            setImageOption1(background2);
            setImageOption2(background1);
            setOption("2");
        } else if(e.target.value === "3") {
            setImageOption1(colors2);
            setImageOption2(colors1);
            setOption("3");
        }
      }

      useEffect(() => {
        updateProfile();
    }, [])

    const updateProfile = async () => {
        
        const request = await callEndpoint(profile(userState.user.id));
        console.log(request);
        const data = profileAdapter(request.data);
        if (request.data.status === "success") {
            dispatch(updateUser(data));
        }
    }

return (
    <>
        <div className='selector-tab'>
            <div class="tab-container">
                <input type="radio" name="tab" value="1" id="tab1" class="tab tab--1" 
                    onChange={onOptionChange} />
                <label class="tab_label" for="tab1">Mejorar imagen</label>

                <input type="radio" name="tab" value="2" id="tab2" class="tab tab--2" 
                    onChange={onOptionChange} />
                <label class="tab_label" for="tab2">Eliminar fondo</label>

                <input type="radio" name="tab" value="3" id="tab3" class="tab tab--3" 
                    onChange={onOptionChange} />
                <label class="tab_label" for="tab3">Mejorar el color de la imagen</label>

                <div class="indicator"></div>
            </div>
        </div>
        <div className='diane-ai'>

            {loading ? <div className='overlay'><Loader /></div> : ''}

            <div className='comparer-diane-ai'>
                <ReactCompareImage aspectRatio="wider" leftImage={getImageUpscaled ? originalImage : imageOption2} rightImage={getImageUpscaled ? getImageUpscaled : imageOption1} />
            </div>

            <DropZone setOriginalImage={setOriginalImage} imageUpscaled={imageUpscaled} getImage={getImage} setImageUpscaled={setImageUpscaled} setGetImageUpscaled={setGetImageUpscaled} setLoading={setLoading} option={option}/>
        </div>
    </>
)
}
