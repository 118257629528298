import React from 'react'
import { FaRegCircleCheck } from "react-icons/fa6";
import 'animate.css';

export const PaymentsConfirmPage = () => {
  return (
    <div className='confirm_payment'>
        <FaRegCircleCheck size={'14em'} color='rgb(26 179 86)'/>
        <h2>Pago confirmado!!</h2>
        <h2>Tus creditos estan de camino.</h2>

    </div>
  )
}
