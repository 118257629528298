import React, { useEffect, useRef, useState } from 'react'
import background from '../../assets/video/background_old.mp4';
import prevideo from '../../assets/img/pre-video.png';

import { useForm } from '../../hooks/useForm'
import { Global } from '../../helpers/Global';
import useAuth from '../../hooks/useAuth'
import { Link } from 'react-router-dom';
import { loginService } from '../../services/public.service';
import { createUserAdapter } from '../../adapters/user.adapter';
import { useDispatch } from 'react-redux';
import { login } from '../../redux/states/auth';
import Popup from 'reactjs-popup';
import { Dialog } from '../layout/utils/Dialog';
import useFetch from '../../hooks/useFetch';
import { LazyLoadImage } from "react-lazy-load-image-component";

export const Login = () => {

    const { form, changed } = useForm({});
    const [loginState, setLogin] = useState("not_login");
    const [open, setOpen] = useState(false);
    const [headerResponse, setHeaderResponse] = useState(false);
    const [descriptionResponse, setDescriptionResponse] = useState(false);
    const [isVideoVisible, setIsVideoVisible] = useState(false);
    const dispatch = useDispatch();
    const { callEndpoint } = useFetch();
    const videoRef = useRef(null);

    useEffect(() => {
        const handlePlay = () => {
            playVideo();
        };

        const videoElement = videoRef.current;
        videoElement.addEventListener('play', handlePlay);

        return () => {
            videoElement.removeEventListener('play', handlePlay);
        };
    }, []);


    const playVideo = () => {
        setIsVideoVisible(true);
    };

    const closeModal = () => {
        setOpen(false);
    }

    const loginUser = async (e) => {
        e.preventDefault();
        try {
            let userToLogin = form;
            const request = await callEndpoint(loginService(userToLogin));
            const data = createUserAdapter(request.data);

            if (request.data.status === "success") {
                dispatch(login(data));
                setLogin("login");
            } else {
                setError();
            }
        } catch (error) {
            setError();
        }

    }

    const setError = () => {
        setHeaderResponse("Error en la autenticación");
        setDescriptionResponse("Hubo un error con el usuario/contraseña");
        setOpen(o => !o);
    }
    return (<>
        <div className='login-page card-container'>
            <Popup open={open} closeOnDocumentClick onClose={closeModal}>
                <Dialog header={headerResponse ? headerResponse : "Te has autenticado"} description={descriptionResponse ? descriptionResponse : "Ahora puedes iniciar sesión"} Accept={closeModal} acceptText="Aceptar" />
            </Popup>
            <div id="preVideo" className={`videoTag ${isVideoVisible ? 'fade-out' : 'fade-in'}`} >
                <LazyLoadImage src={prevideo} />
            </div>
            <video className={`videoTag ${isVideoVisible ? 'fade-in' : 'fade-out'}`} ref={videoRef} autoPlay loop muted>
                <source src={background} type='video/mp4' />
            </video>
            <div class="circle1"></div>
            <div class="circle2"></div>
            <div class="container">
                <div class="log-card">
                    <p class="heading">Bienvenido</p>
                    <p>Nos alegra tenerte de nuevo.</p>
                    <form className='form-login' onSubmit={loginUser}>
                        <div class="input-group">
                            <label htmlFor="email" class="text">Email</label>
                            <input class="input" type="email" name="email" onChange={changed} placeholder="Enter Email" />
                            <label htmlFor='password' class="text">Contraseña</label>
                            <input class="input" name="password" type="password" onChange={changed} placeholder="Enter Password" />
                        </div>
                        <input type='submit' className="btn" value="Login" />
                    </form>
                    <div class="password-group">
                        <div class="checkbox-group">
                            <input type="checkbox" />
                            <label class="label">Recuerdame</label>
                        </div>
                        <Link to="/remember_password" class="forget-password">Olvide mi contraseña</Link>
                    </div>


                    <p class="no-account">No tienes una cuenta ?<a class="link"> <Link to="/register">Registrate</Link></a></p>
                </div>
            </div>
        </div>
    </>
    )
}
