import React, { useEffect, useState } from 'react'
import { FaRegCircleCheck } from "react-icons/fa6";
import 'animate.css';
import { useParams } from 'react-router-dom';
import { activateUserService } from '../../services/public.service';
import useFetch from '../../hooks/useFetch';

export const ConfirmPage = () => {
    const [activate, setActivate] = useState(null);
    const params = useParams();
    const { callEndpoint } = useFetch();

    useEffect(async () => {
        const token = params.token;
        const activateUser = await callEndpoint(activateUserService(token))
        if (activateUser.data.status === "success") {
            setActivate(true);
        } else {
            setActivate(false);
        }
    }, [])

    return (
        <>
            {activate === true &&
                <div className='confirm_payment'>
                    <FaRegCircleCheck size={'14em'} color='rgb(26 179 86)' />
                    <h1>Registro confirmado!!</h1>
                    <h2>Ya puedes iniciar sesion.</h2>

                </div>
            }
            {activate === false &&
                <div className='confirm_payment'>
                    <FaRegCircleCheck size={'14em'} color='rgb(26 179 86)' />
                    <h1>No ha podido realizarse el registro!</h1>
                    <h2>Hubo un problema</h2>

                </div>
            }

        </>
    )
}
