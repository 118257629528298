import React, { useEffect } from 'react'
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { PaymentsForm } from './PaymentsForm';
import { useState } from 'react';
import { paymentSecret } from '../../../services/public.service';
import { paymentIntentAdapter } from '../../../adapters/payments.adapter';
import { Card } from '../../layout/utils/Card';
import { useParams } from 'react-router-dom';
import { Products } from '../../../helpers/Global';
import { usePaymentContext } from '../context/payments.context';
import useFetch from '../../../hooks/useFetch';

export const PaymentsLayout = () => {

    const [options, setOptions] = useState(null);
    const [intentId, setIntentId] = useState(null);
    const {type, setType } = usePaymentContext();
    const params = useParams();
    const appearance = {
        theme: 'stripe'
      };
    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISH_KEY);
    const { callEndpoint } = useFetch();

    useEffect(() => {
        
        if (!intentId) {
            setType(params.type)
            preparePayment(params.type);
        }
    }, [])

    const preparePayment = async (amount) => {
        
        const request = await callEndpoint(paymentSecret(amount));
        const data = paymentIntentAdapter(request.data);
        setIntentId(data.intent.id);
        setOptions({
            clientSecret: data.clientSecret,
            appearance: {
                theme: 'stripe' 
              }
        });
    }

    return (
        <div className='paymentPage'>
            <div className='product'>
            <Card price={type? Products[type].price: ""} title={type? type.toUpperCase(): ""} subtitle={type? Products[type].subtitle: ""} description={type? Products[type].description: ""} />
            </div>
            <div className='paymentForm'>
                {options &&
                    <Elements stripe={stripePromise} options={options}>
                        <PaymentsForm />
                    </Elements>
                }
            </div>
        </div>
    )
}
